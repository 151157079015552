/*
 * Full screen page related
 */
const IS_FULL_SCREEN_PAGE = 'LAYOUT_IS_FULL_SCREEN_PAGE';
export const isFullScreenPage = (isFullScreenPage = false) => ({
    type: IS_FULL_SCREEN_PAGE,
    isFullScreenPage,
});

const UPDATE_SCREEN_TYPE = 'LAYOUT_UPDATE_SCREEN_TYPE';
export function updateScreenType(screenType = '') {
    return {
        type: UPDATE_SCREEN_TYPE,
        screenType,
    };
}
/*
 * App navigation related
 */
const UPDATE_APP_NAVIGATION_COLLAPSE = 'LAYOUT_UPDATE_APP_NAVIGATION_COLLAPSE';
export const updateAppNavigationCollapse = (collapse = null) => ({
    type: UPDATE_APP_NAVIGATION_COLLAPSE,
    collapse,
});
const OPEN_XS_SCREEN_APP_NAVIGATION = 'LAYOUT_OPEN_XS_SCREEN_APP_NAVIGATION';
export function openXsScreenAppNavigation(open = false) {
    return {
        type: OPEN_XS_SCREEN_APP_NAVIGATION,
        open,
    };
}

/*
 * MDEC Program related
 */
const SHOW_MDEC_PROGRAM_ACTIVATION_VIEW = 'LAYOUT_SHOW_MDEC_PROGRAM_ACTIVATION_VIEW';
export function showMdecProgramActivationView(show = false) {
    return {
        type: SHOW_MDEC_PROGRAM_ACTIVATION_VIEW,
        show,
    };
}
const IS_MDEC_PROGRAM_ACTIVATION_VIEW_BTN_CLICKED =
    'LAYOUT_IS_MDEC_PROGRAM_ACTIVATION_VIEW_BTN_CLICKED';
export function isMdecProgramActivationViewBtnClicked(isBtnClicked = false) {
    return {
        type: IS_MDEC_PROGRAM_ACTIVATION_VIEW_BTN_CLICKED,
        isBtnClicked,
    };
}

/*
 * Startup Program related
 */
const SHOW_STARTUP_PROGRAM_ACTIVATION_VIEW = 'LAYOUT_SHOW_STARTUP_PROGRAM_ACTIVATION_VIEW';
export function showStartupProgramActivationView(show = false) {
    return {
        type: SHOW_STARTUP_PROGRAM_ACTIVATION_VIEW,
        show,
    };
}
const IS_STARTUP_PROGRAM_ACTIVATION_VIEW_BTN_CLICKED =
    'LAYOUT_IS_STARTUP_PROGRAM_ACTIVATION_VIEW_BTN_CLICKED';
export function isStartupProgramActivationViewBtnClicked(isBtnClicked = false) {
    return {
        type: IS_STARTUP_PROGRAM_ACTIVATION_VIEW_BTN_CLICKED,
        isBtnClicked,
    };
}

/*
 * Sesson Expiration View
 */
const SHOW_SESSION_EXPIRATION_VIEW = 'LAYOUT_SHOW_SESSION_EXPIRATION_VIEW';
export function showSessionExpirationView(show = false) {
    return {
        type: SHOW_SESSION_EXPIRATION_VIEW,
        show,
    };
}

/*
 * CNY Promotion related
 */
const SHOW_CNY_PROMOTION_WELCOME_VIEW = 'LAYOUT_SHOW_CNY_PROMOTION_WELCOME_VIEW';
export function showCNYPromotionWelcomeView(show = false, title = '', message = '') {
    return {
        type: SHOW_CNY_PROMOTION_WELCOME_VIEW,
        show,
        title,
        message,
    };
}

/*
 * Onboarding related (new pricing plan)
 */
const SHOW_ONBOARDING_WELCOME_VIEW = 'LAYOUT_SHOW_ONBOARDING_WELCOME_VIEW';
export function showOnboardingWelcomeView(
    show = false,
    onboardingType = '',
    title = '',
    message = '',
    imageUrl = '',
    button = '',
    buttonUrl = '',
) {
    return {
        type: SHOW_ONBOARDING_WELCOME_VIEW,
        show,
        onboardingType,
        title,
        message,
        imageUrl,
        button,
        buttonUrl,
    };
}

const UPDATE_HOME_PROMOTION = 'LAYOUT_UPDATE_HOME_PROMOTION';
export function updateHomePromotion(homePromotion = null) {
    return {
        type: UPDATE_HOME_PROMOTION,
        homePromotion,
    };
}

/*
 * SwingvyReducer로 노출시킬 reducer
 */
const initState = {
    screenType: '',
    topActionBar: {
        show: false,
        message: '',
        actionBtnTitle: 'Save',
        onClickActionBtn: () => {},
    },
    appNavigation: {
        openXsScreenSize: false,
        collapse: null,
    },
    mdecProgramActivationView: {
        show: false,
        isBtnClicked: false,
    },
    startupProgramActivationView: {
        show: false,
        isBtnClicked: false,
    },
    sessionExpirationView: {
        show: false,
    },
    cnyPromotionWelcomeView: {
        show: false,
        title: '',
        message: '',
    },
    onboardingWelcomeView: {
        show: false,
        onboardingType: '',
        title: '',
        message: '',
        imageUrl: '',
        button: '',
        buttonUrl: '',
    },
    homePromotion: null,
    isFullScreenPage: false,
};
export function reducer(state = initState, action) {
    let mdecProgramActivationView = null;
    let startupProgramActivationView = null;
    let appNavigation = null;

    switch (action.type) {
        case UPDATE_APP_NAVIGATION_COLLAPSE:
            appNavigation = { ...state.appNavigation, collapse: action.collapse };
            return { ...state, appNavigation };

        case OPEN_XS_SCREEN_APP_NAVIGATION:
            appNavigation = { ...state.appNavigation, openXsScreenSize: action.open };
            return { ...state, appNavigation };

        case SHOW_MDEC_PROGRAM_ACTIVATION_VIEW:
            mdecProgramActivationView = { ...state.mdecProgramActivationView, show: action.show };
            return { ...state, mdecProgramActivationView };

        case IS_MDEC_PROGRAM_ACTIVATION_VIEW_BTN_CLICKED:
            mdecProgramActivationView = {
                ...state.mdecProgramActivationView,
                isBtnClicked: action.isBtnClicked,
            };
            return { ...state, mdecProgramActivationView };

        case SHOW_STARTUP_PROGRAM_ACTIVATION_VIEW:
            startupProgramActivationView = {
                ...state.startupProgramActivationView,
                show: action.show,
            };
            return { ...state, startupProgramActivationView };

        case IS_STARTUP_PROGRAM_ACTIVATION_VIEW_BTN_CLICKED:
            startupProgramActivationView = {
                ...state.startupProgramActivationView,
                isBtnClicked: action.isBtnClicked,
            };
            return { ...state, startupProgramActivationView };

        case SHOW_SESSION_EXPIRATION_VIEW:
            const sessionExpirationView = { ...state.sessionExpirationView, show: action.show };
            return { ...state, sessionExpirationView };

        case SHOW_CNY_PROMOTION_WELCOME_VIEW:
            const cnyPromotionWelcomeView = {
                ...state.cnyPromotionWelcomeView,
                show: action.show,
                title: action.title,
                message: action.message,
            };
            return { ...state, cnyPromotionWelcomeView };

        case SHOW_ONBOARDING_WELCOME_VIEW:
            const onboardingWelcomeView = {
                ...state.onboardingWelcomeView,
                show: action.show,
                onboardingType: action.onboardingType,
                title: action.title,
                message: action.message,
                imageUrl: action.imageUrl,
                button: action.button,
                buttonUrl: action.buttonUrl,
            };
            return { ...state, onboardingWelcomeView };

        case UPDATE_HOME_PROMOTION:
            return { ...state, ...action.homePromotion };

        case IS_FULL_SCREEN_PAGE:
            return { ...state, isFullScreenPage: action.isFullScreenPage };

        case UPDATE_SCREEN_TYPE:
            return { ...state, screenType: action.screenType };

        default:
            return state;
    }
}
