import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const getUserLanguage = () => {
    let language = (
        (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
        navigator.language || // All browsers
        navigator.userLanguage || // IE <= 10
        ''
    ).toLowerCase(); // for exceptional case

    console.log('bl', language);

    if (language === 'zh-tw') {
        language = 'zh-Hant-TW';
    } else if (language.includes('zh')) {
        language = 'zh-Hans';
    } else {
        language = 'en';
    }

    return language;
};

export default function ({ projects, defaultProject }) {
    const otherProjects = projects.filter((project) => project !== defaultProject);

    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
            returnNull: false,
            ns: projects,
            defaultNS: defaultProject,
            fallbackNS: otherProjects,
            lng: getUserLanguage(),
            lowerCaseLng: true,
            fallbackLng: {
                default: ['en'],
            },
            debug: process.env.NODE_ENV !== 'production',
            interpolation: {
                escapeValue: false,
            },
            react: {
                bindI18n: 'languageChanged',
                bindI18nStore: '',
                transEmptyNodeValue: '',
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span', 'b', 'italic', 'em'],
                useSuspense: false,
            },
            keySeparator: false,
        });

    return i18n;
}
