import Raven from 'raven-js';

const SENTRY_KEY_DEV = 'https://b2a98cfc766348f5be9c10007e3bc8bd@sentry.io/1283697';
const SENTRY_KEY_REAL = 'https://5a79fb4b1bd84fce98d06e74dfe51dd4@sentry.io/1283818';

class Logger {
    constructor() {
        this.init = this.init.bind(this);
        this.setContext = this.setContext.bind(this);
        this.log = this.log.bind(this);
    }

    init() {
        // https://docs.sentry.io/clients/javascript/config/
        if (!Raven.isSetup()) {
            const { PHASE } = process.env;
            const ravenDSN =
                PHASE === 'real' || PHASE === 'demo' ? SENTRY_KEY_REAL : SENTRY_KEY_DEV;
            Raven.config(ravenDSN, {
                release: process.env.APP_VERSION,
            }).install();
        }
    }

    setContext({ company, user }) {
        if (Raven && Raven.isSetup()) {
            Raven.setTagsContext({
                hash: Raven._location.hash || '',
                companyId: company.id,
                userId: user.id,
                userEmail: user.email,
            });
            Raven.setUserContext({
                companyId: company.id,
                companyName: company.name,
                companyOffices: company.companyOffices,
                companyGrades: company.companyGrades,
                userEmail: user.email,
                userId: user.id,
                userStatus: user.userStatus,
            });
        }
    }

    log(error, extraOption = null) {
        try {
            if (Raven && Raven.isSetup()) {
                Raven.captureException(error, {
                    extra: extraOption,
                });
            }
            console.log({ error, extraOption });
        } catch (e) {
            console.log(e);
        }
    }
}

if (!Logger.sInstance) {
    Logger.sInstance = new Logger();
}

export default Logger.sInstance;
