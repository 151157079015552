import i18n from 'i18next';
import { ConstantsUtil, DateUtil } from '@swingvy/frontend-util';

export const DATE_FORMAT = { ...DateUtil.DATE_FORMAT, DATE_MY_LONG: 'MMMM YYYY' };
export const {
    GROUP_PERMISSION,
    USER_STATUS,
    TIMEOFF_TYPE,
    FILE_UPLOAD_SIZE_MAX,
    CURRENCY_CODE,
    CURRENCY_SYMBOL,
    COUNTRY_CODE,
} = ConstantsUtil;

export const APP_COUNTDOWN_TIME = 25 * 60 * 1000;

export const ERROR_CODE = {
    INVALID_SESSION: 601,
    ACCESS_TOKEN_EXPIRED: 613,
    SHOULD_MOVE_TO_SWITCH_ACCOUNT_PAGE: 620,
};

export const PAYMENT_METHOD = {
    BANK_TRANSFER: 'DEFERRED',
    CREDIT_CARD: 'CREDIT_CARD',
};

export const getCurrencySymbolFromCode = (currencyCode) => {
    // eslint-disable-next-line default-case
    switch (currencyCode) {
        case CURRENCY_CODE.MALAYSIA:
            return CURRENCY_SYMBOL.MALAYSIA;
        case CURRENCY_CODE.SINGAPORE:
            return CURRENCY_SYMBOL.SINGAPORE;
        case CURRENCY_CODE.TAIWAN:
            return CURRENCY_SYMBOL.TAIWAN;
    }
    return '';
};

export const getCurrencySymbolFromCountryCode = (countryCode) => {
    // eslint-disable-next-line default-case
    switch (countryCode) {
        case COUNTRY_CODE.MALAYSIA:
            return CURRENCY_SYMBOL.MALAYSIA;
        case COUNTRY_CODE.SINGAPORE:
            return CURRENCY_SYMBOL.SINGAPORE;
        case COUNTRY_CODE.TAIWAN:
            return CURRENCY_SYMBOL.TAIWAN;
    }
    return '';
};

export const getTimeoffStatus = (status) => {
    // 'Pending', 'Approved', 'Declined'
    switch (status) {
        case 0:
            return i18n.t('leave.form.leave_status.pending'); // 'Pending'
        case 1:
            return i18n.t('leave.form.leave_status.approved'); // 'Approved'
        case 2:
            return i18n.t('leave.form.leave_status.declined'); // 'Declined'
        default:
            return null;
    }
};

export const getUserStatus = (status) => {
    switch (status) {
        case 0:
        case 4:
            return i18n.t('common.user_status.onboarding');
        case 1:
            return i18n.t('common.user_status.active');
        case 2:
            return i18n.t('common.user_status.offboarding');
        case 3:
            return i18n.t('common.user_status.resigned');
        case 5:
            return i18n.t('common.user_status.on_leave');
        case 6:
            return i18n.t('people.filter_modal.status.option.pending_onboarding');
        case 999:
            return i18n.t('common.user_status.deleted');
        default:
            return '';
    }
};

export const isDevPhase = (phase) => {
    return ['local', 'alpha', 'qa'].includes(phase);
};

export const COMPANY_LOGO_IMAGE_ACCEPTS = '.gif,.jpeg,.jpg,.png';
export const ACCOUNT_PROFILE_IMAGE_ACCEPTS = '.gif,.jpg,.png';
export const MANAGE_PEOPLE_BULK_UPLOAD_ACCEPTS = '.xls,.xlsx';
export const LEAVE_REQUEST_FILE_ATTACHMENT_ACCEPTS =
    'image/*,application/pdf,.heic,.xlsx,.xls,.csv,.docx,.doc,.msg';
export const CLAIMS_ITEM_FILE_ATTACHMENT_ACCEPTS = 'image/*,application/pdf';

export const CUSTOM_FIELD_TYPE = {
    TEXT: 'TEXT',
    TEXTAREA: 'TEXTAREA',
    FILE: 'FILE',
};
