import { QueryClient } from '@tanstack/react-query';
import { toast } from '@voyagers/toast';

const errorHandler = (error) => {
    if (error.title) {
        toast.error({
            header: error.title,
            body: error.message,
        });
    } else {
        toast.error({
            header: error.message,
        });
    }
};

export const queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            onError: errorHandler,
            retry: 1,
        },
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 60000,
            onError: errorHandler,
            retry: 1,
        },
    },
});
