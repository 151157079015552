import { handleActions } from 'redux-actions';

import { updateStaffanyEnabled, updateEmployeesMapping } from './action';

const initState = {
    isStaffanyEnabled: false,
    employeesMapping: {},
};

export const integrations = handleActions(
    {
        [updateStaffanyEnabled]: (state, { payload }) => ({
            ...state,
            isStaffanyEnabled: payload,
        }),
        [updateEmployeesMapping]: (state, { payload }) => ({
            ...state,
            employeesMapping: [...payload],
        }),
    },
    initState,
);
