const UPDATE_SURVEY = 'SURVEY_UPDATE';
export function updateSurvey(selectedItemId = null, selectedText = '', text = '') {
    return {
        type: UPDATE_SURVEY,
        selectedItemId,
        selectedText,
        text,
    };
}

const initState = {
    selectedItemId: null,
    selectedText: '',
    text: '',
};
export function reducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_SURVEY:
            return Object.assign({}, state, {
                selectedItemId: action.selectedItemId,
                selectedText: action.selectedText,
                text: action.text,
            });
        default:
            return state;
    }
}
