import axios from 'axios';
import store from 'store';
import { Map } from 'immutable';
import { PROJECT } from './Constants';

const BASE_URL = 'https://h697j20c44.execute-api.ap-southeast-1.amazonaws.com/prod';
const KEY_LOCALE = 'language_locale';
const KEY_VERSIONS = 'language_versions';
const KEY_TRANSLATIONS = 'language_translations';

const fetchVersionsPromise = (translationInfo) => {
    return axios
        .get(`${BASE_URL}/version`, {
            params: {
                project: Object.values(PROJECT).join(','),
            },
        })
        .then((res) => {
            const map = Map(translationInfo);
            return map.set('originVersions', res.data).toJSON();
        });
};

const fetchTranslationsPromise = (translationInfo) => {
    const { projectNamesToFetch } = translationInfo;
    return axios
        .get(`${BASE_URL}/download`, {
            params: {
                project: projectNamesToFetch.join(','),
                languageCode: translationInfo.locale,
                dev: translationInfo.isDev,
                flatJson: true,
            },
        })
        .then((response) => {
            const map = Map(translationInfo);
            return map.set('originTranslations', response.data).toJSON();
        });
};

const getProjectNamesToFetchPromise = (translationInfo) => {
    const { originVersions } = translationInfo;
    const { cacheVersions } = translationInfo;
    const { cacheTranslations } = translationInfo;

    // 버전 정보가 있더라도 만약 같은 프로젝트명의 번역이 실제로 존재하지 않는 경우, 받아야 함
    const projectNames = [];
    Object.keys(PROJECT).forEach((key) => {
        const projectName = PROJECT[key];

        const originVersion = originVersions[projectName].value;
        const cacheVersion = cacheVersions[projectName] && cacheVersions[projectName].value;
        const cacheTranslation = cacheTranslations && cacheTranslations[projectName];

        if (!cacheVersion) {
            projectNames.push(projectName);
        } else if (cacheVersion < originVersion) {
            projectNames.push(projectName);
        } else if (!cacheTranslation) {
            projectNames.push(projectName);
        }
    });
    const map = Map(translationInfo);
    const updatedInfo = map.set('projectNamesToFetch', projectNames).toJSON();
    return Promise.resolve(updatedInfo);
};

const updateI18nPromise = (i18n, translationInfo) => {
    return Promise.resolve(translationInfo).then((info) => {
        const translations = info.cacheTranslations;
        const locale = info.locale.toLowerCase();
        Object.values(PROJECT).forEach((projectName) => {
            i18n.addResourceBundle(locale, projectName, translations[projectName], true, true);
        });
        i18n.changeLanguage(locale);

        return translationInfo;
    });
};

const saveToLocalPromise = (translationInfo) => {
    return new Promise((resolve, reject) => {
        const translations = {
            ...translationInfo.cacheTranslations,
            ...translationInfo.originTranslations,
        };

        store.set(KEY_LOCALE, translationInfo.locale);
        store.set(KEY_VERSIONS, translationInfo.originVersions);
        store.set(KEY_TRANSLATIONS, translations);

        const map = Map(translationInfo);
        const versionUpdatedMap = map.set('cacheVersions', translationInfo.originVersions);
        const translationUpdatedMap = versionUpdatedMap.set('cacheTranslations', translations);

        resolve(translationUpdatedMap.toJSON());
    });
};

const clearCache = () => {
    store.remove(KEY_LOCALE);
    store.remove(KEY_VERSIONS);
    store.remove(KEY_TRANSLATIONS);
};

const recognizeLocale = (session, i18n) => {
    let locale = null;
    const userLocale = session ? session.user.language.code : i18n.language;
    const cacheLocale = store.get(KEY_LOCALE);
    if (!cacheLocale) {
        clearCache();
        locale = userLocale;
    } else if (!session) {
        locale = cacheLocale;
        if (cacheLocale !== userLocale) {
            clearCache();
            locale = userLocale;
        }
    } else {
        locale = cacheLocale;
        if (cacheLocale !== userLocale) {
            clearCache();
            locale = userLocale;
        }
    }
    return locale;
};

export default function (i18n) {
    return {
        init: (renderApp, options = { session: null, isDev: false }) => {
            const session = options.session || null;
            const isDev = options.isDev || false;
            if (isDev) {
                clearCache();
            }

            const renderAppPromise = (info /* translationInfo */) => {
                return new Promise((resolve) => {
                    renderApp(options);
                    resolve(info);
                });
            };

            const translationInfo = {
                isDev,
                session,
                locale: recognizeLocale(session, i18n),
                originVersions: null,
                originTranslations: null,
                cacheVersions: store.get(KEY_VERSIONS),
                cacheTranslations: store.get(KEY_TRANSLATIONS),
                projectNamesToFetch: null,
            };

            if (translationInfo.cacheVersions) {
                return Promise.resolve(translationInfo)
                    .then((info) => updateI18nPromise(i18n, info))
                    .then((info) => renderAppPromise(info))
                    .then((info) => fetchVersionsPromise(info))
                    .then((info) => getProjectNamesToFetchPromise(info))
                    .then((info) => {
                        const { projectNamesToFetch } = info;
                        if (!projectNamesToFetch || projectNamesToFetch.length === 0) {
                            // 최신
                            return Promise.resolve({
                                ...info,
                                originTranslations: info.cacheTranslations,
                            });
                        }
                        return fetchTranslationsPromise(info);
                    })
                    .then((info) => saveToLocalPromise(info))
                    .then((info) => updateI18nPromise(i18n, info))
                    .then((info) => options);
            }
            translationInfo.projectNamesToFetch = Object.values(PROJECT);
            return Promise.resolve(translationInfo)
                .then((info) =>
                    Promise.all([fetchVersionsPromise(info), fetchTranslationsPromise(info)]),
                )
                .then((values) => {
                    return { ...values[0], originTranslations: values[1].originTranslations };
                })
                .then((info) => saveToLocalPromise(info))
                .then((info) => updateI18nPromise(i18n, info))
                .then((info) => renderAppPromise(info))
                .then((info) => options);
        },
    };
}
