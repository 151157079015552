import i18n from 'i18next';
import RoutePath from 'js/app/RoutePath';
import ErrorModalView from 'js/app/view/common/ErrorModalView';
import Logger from 'js/helper/Logger';
import axios from 'axios';
import API from 'js/network/Api';
import { SwingvyUtil } from '@swingvy/frontend-util';
import { ERROR_CODE } from 'js/util/Constants';

export const handleSwingvyCoreError = ({ status, responseJSON }) => {
    let isErrorHandled = false;
    try {
        if (!status === 500) {
            return isErrorHandled;
        }

        const { code, title, message } = responseJSON;
        if (code === ERROR_CODE.INVALID_SESSION) {
            isErrorHandled = true;
            location.href = RoutePath.LOGOUT;
        } else if (code === ERROR_CODE.ACCESS_TOKEN_EXPIRED) {
            axios.post(API.REFRESH_TOKEN.url).then((result) => console.log(result));
        } else if (code === ERROR_CODE.SHOULD_MOVE_TO_SWITCH_ACCOUNT_PAGE) {
            isErrorHandled = true;
            location.replace(`${SwingvyUtil.getHrisHost()}/switch-account.html`);
        }

        return isErrorHandled;
    } catch (error) {
        Logger.log(error);
        return isErrorHandled;
    }
};

export class RequestErrorHandler {
    constructor(jqXHR, textStatus, thrownError) {
        this.jqXHR = jqXHR;
        this.textStatus = textStatus;
        this.thrownError = thrownError;

        this.getError = this.getError.bind(this);
        this.getPresenterError = this.getPresenterError.bind(this);
        this.getErrorModal = this.getErrorModal.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    getError() {
        const { jqXHR } = this;
        return {
            status: jqXHR.status,
            message: jqXHR.responseJSON ? jqXHR.responseJSON.message : jqXHR.statusText,
            code: jqXHR.responseJSON ? jqXHR.responseJSON.code : null,
            data: jqXHR.responseJSON ? jqXHR.responseJSON.data : null,
        };
    }

    getErrorModal() {
        return new ErrorModalView();
    }

    getPresenterError(title, message) {
        return {
            title,
            message,
        };
    }

    handleError() {
        const { jqXHR } = this;
        const { textStatus } = this;
        const { thrownError } = this;
        let presenter = null;
        try {
            if (jqXHR.status === 0) {
                presenter = this.getPresenterError('Offline', 'Please check your network.');
            } else if (jqXHR.status === 403) {
                presenter = this.getPresenterError(
                    'Sorry! Access denied :(',
                    "You don't have permission toto open this page.",
                );
            } else if (jqXHR.status === 404) {
                presenter = this.getPresenterError(
                    jqXHR.statusText,
                    'Sorry, the page you tried cannot be found',
                );
            } else if (jqXHR.status === 500) {
                if (jqXHR.responseJSON) {
                    const { code, title, message } = jqXHR.responseJSON;
                    presenter = this.getPresenterError(title, message);
                } else {
                    presenter = this.getPresenterError(
                        jqXHR.statusText,
                        'Something went wrong. We will check this error shortly.',
                    );
                }
            } else if (textStatus === 'parsererror') {
                presenter = this.getPresenterError('Parse Error', 'Requested JSON parse failed.');
            } else if (textStatus === 'timeout') {
                presenter = this.getPresenterError(
                    'Timeout',
                    'There was a problem with your request. Please try again.',
                );
            } else {
                presenter = this.getPresenterError(
                    jqXHR.statusText,
                    thrownError || jqXHR.statusText || '',
                );
            }
        } catch (error) {
            Logger.log(error);
            presenter = this.getPresenterError(
                i18n.t('common.oops'),
                i18n.t('error.msg_unknown_error'),
            );
        }

        const errorModalView = new ErrorModalView();
        errorModalView.show(presenter);
    }
}
