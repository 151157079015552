const DETECT_USER_ACTIVITY = 'EVENT_DETECT_USER_ACTIVITY';
export function detectUserActivity(isDetected = true) {
    return {
        type: DETECT_USER_ACTIVITY,
        isUserActivityDetected: isDetected,
    };
}

const initState = {
    isUserActivityDetected: false,
};
export function reducer(state = initState, action) {
    switch (action.type) {
        case DETECT_USER_ACTIVITY:
            return Object.assign({}, state, {
                isUserActivityDetected: action.isUserActivityDetected,
            });
        default:
            return state;
    }
}
