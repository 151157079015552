import i18n from 'i18next';
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Handlebars from 'handlebars';
import modalHtml from 'text-loader!templates/app/common/modal/modal_error.html';

export default Backbone.View.extend({
    id: 'errorModal',
    className: 'modal swv-modal critical fade',
    attributes: {
        role: 'dialog',
        'aria-hidden': 'true',
    },
    events: {
        'click ._close_box': 'onClickCloseBox',
    },
    template: Handlebars.compile(modalHtml),
    initialize() {
        _.bindAll(this, 'show', 'hide', 'onClickCloseBox');
        this.render();
    },
    render() {
        this.$el.html(
            this.template({
                okayText: i18n.t('common.okay'),
            }),
        );
        if ($('#errorModal').length == 0) {
            $('body').append(this.el);
        }

        this.$el.on('show.bs.modal', () => {
            this.$el.find('._title').text(this.title);
            this.$el.find('._message').text(this.message);
        });
    },
    show(options) {
        if (options) {
            this.data = options.data;
            this.title = options.title;
            this.message = options.message;
        }
        this.$el.modal('show');
    },
    hide() {
        this.$el.modal('hide');
    },
    onClickCloseBox(ev) {
        this.$el.modal('hide');
    },
});
