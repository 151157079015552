import jQuery from 'jquery';
import 'jquery-migrate';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/position';
import 'jquery-ui/ui/effect';
import 'jquery-ui/ui/widgets/accordion';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/button';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui/ui/widgets/menu';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/progressbar';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/selectable';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/spinner';
import 'jquery-ui/ui/widgets/tabs';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/ui/effects/effect-blind';
import 'jquery-ui/ui/effects/effect-bounce';
import 'jquery-ui/ui/effects/effect-clip';
import 'jquery-ui/ui/effects/effect-drop';
import 'jquery-ui/ui/effects/effect-explode';
import 'jquery-ui/ui/effects/effect-fade';
import 'jquery-ui/ui/effects/effect-fold';
import 'jquery-ui/ui/effects/effect-highlight';
import 'jquery-ui/ui/effects/effect-pulsate';
import 'jquery-ui/ui/effects/effect-scale';
import 'jquery-ui/ui/effects/effect-shake';
import 'jquery-ui/ui/effects/effect-slide';
import 'jquery-ui/ui/effects/effect-transfer';
import 'bootstrap';
import 'plugins/jquery-metadata/jquery.metadata.min';
import 'jquery-validation';
import 'chosen-js';
import 'bootstrap-multiselect';
import 'picker';
import 'pickadate/lib/picker.date';
import 'bootstrap-datepicker';
import 'bootstrap-daterangepicker';
import 'bootstrap-switch';
import 'parsleyjs';
import 'select2/dist/js/select2.full';
import 'plugins/number/jquery.number';
import 'multiselect/js/jquery.multi-select';
import 'plugins/quicksearch/jquery.quicksearch';
import 'bootstrap-select';
import 'plugins/table-edit/jquery.tabledit';
import 'plugins/nestable/jquery.nestable';
import 'plugins/ionRangeSlider/ion.rangeSlider';
import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-buttons';
import 'fullcalendar/dist/fullcalendar';
import 'fullcalendar/dist/lang-all';
import 'footable/dist/footable.all.min';
import 'jstree';

export default jQuery;
