
import RoutePath from 'js/app/RoutePath';

const escape = s => `${s}`.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

const PageRegExp = {
    Login: RegExp(escape(RoutePath.LOGIN)),
    ForgotPassword: RegExp(escape(RoutePath.FORGOT_PASSWORD)),
    NewPassword: RegExp(escape(RoutePath.NEW_PASSWORD)),
}

let documentTitleManager = null;
export const initialize = (i18n) => {
    
    const _i18n = i18n;
    const _titleHandler = () => {
        try {
            const hash = window.location.hash;
            if(PageRegExp.Login.test(hash)) {
                document.title = _i18n.t('document_title.login_page');
            }else if(PageRegExp.ForgotPassword.test(hash)) {
                document.title = _i18n.t('document_title.forgot_password_page');
            }else if(PageRegExp.NewPassword.test(hash)) {
                document.title = _i18n.t('document_title.swingvy');
            }else {
                document.title = _i18n.t('document_title.swingvy');
            }
        }catch(e) {
            console.log(e);
            document.title = 'Swingvy'
        }
    }

    if(!documentTitleManager) {
        documentTitleManager = (function manager() {
            _titleHandler();
            window.addEventListener('hashchange', _titleHandler, true);
            return manager;
        })();
    }
};