import { AppContainer } from 'react-hot-loader';
import { QueryClientProvider } from '@tanstack/react-query';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'js/redux';
import { queryClient } from 'js/query';
import { ThemeProvider } from '@voyagers/theme';
import { DialogProvider } from '@voyagers/dialog';

// ref: https://github.com/Swingvy/design-system/blob/master/src/patterns/swingvy-grid-system.less
const SWV_SCREEN_WIDTH_XL_MIN = 1440;
const SWV_SCREEN_WIDTH_LG_MIN = 1200;
const SWV_SCREEN_WIDTH_MD_MIN = 992;
const SWV_SCREEN_WIDTH_SM_MIN = 768;

export const getSwingvyScreenType = (screenWidth) => {
    if (screenWidth >= SWV_SCREEN_WIDTH_XL_MIN) {
        return 'xl';
    }
    if (screenWidth >= SWV_SCREEN_WIDTH_LG_MIN) {
        return 'lg';
    }
    if (screenWidth >= SWV_SCREEN_WIDTH_MD_MIN) {
        return 'md';
    }
    if (screenWidth >= SWV_SCREEN_WIDTH_SM_MIN) {
        return 'sm';
    }
    return 'xs';
};

export const scrollTop = (needSmooth = false) => {
    const $target = $('.swv-content-container._swv_scroll_container');
    if (needSmooth) {
        $target.animate({ scrollTop: 0 }, 500);
    } else {
        $target.scrollTop(0);
    }
};

export const scrollTo = (target, destPos, needSmooth = false, duration = 400) => {
    if (needSmooth) {
        $(target).animate(
            {
                scrollTop: destPos,
            },
            duration,
        );
    } else {
        $(target).scrollTop(destPos);
    }
};

export const renderReactElement = (element, container, callback) => {
    ReactDOM.render(
        <AppContainer>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider typoKind="Circular">
                        <DialogProvider targetId="vyg-dialog-target">{element}</DialogProvider>
                        <div id="vyg-dialog-target" />
                    </ThemeProvider>
                </QueryClientProvider>
            </Provider>
        </AppContainer>,
        container,
        callback,
    );
};
