const UPDATE_SESSION = 'ACCOUNT_SESSION_UPDATE';
export function updateSession(value = {}) {
    return {
        type: UPDATE_SESSION,
        session: value,
    };
}

const initState = {
    session: {},
};

export function reducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_SESSION:
            return { ...state, session: action.session };
        default:
            return state;
    }
}
