import Creator from './Creator';
import ResManager from './ResManager';

let i18n = null;
let resManager = null;

export const initI18n = (options = {}) => {
    i18n = Creator(options);
    resManager = ResManager(i18n);
};

export const renderAppWithI18n = (renderApp, appOptions) => {
    if (!i18n) {
        initI18n();
    }
    return new Promise((resolve, reject) => {
        if (i18n.isInitialized) {
            resManager
                .init(renderApp, appOptions)
                .then((options) => resolve(options))
                .catch((error) => reject(error));
        } else {
            i18n.on('initialized', (options) => {
                resManager
                    .init(renderApp, { ...appOptions, ...options })
                    .then((options) => resolve(options))
                    .catch((error) => reject(error));
            });
        }
    });
};

export { PROJECT as I18N_PROJECT } from './Constants';
