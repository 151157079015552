import Store from 'store';

let currentSession;

class LocalStorage {
    constructor() {}

    static setCurrentSession(session) {
        currentSession = session;
        LocalStorage.set('companyId', session.company.id);
    }

    static set(key, value) {
        const savedValue = Store.get(currentSession.user.id);
        if (savedValue) {
            savedValue[key] = { value };
            Store.set(currentSession.user.id, savedValue);
        } else {
            const obj = {};
            obj[key] = { value };
            Store.set(currentSession.user.id, obj);
        }
    }

    static get(key) {
        if (!currentSession) {
            return null;
        }

        const savedValue = Store.get(currentSession.user.id);

        if (!savedValue) {
            return null;
        }
        if (!savedValue[key]) {
            return null;
        }
        return savedValue[key].value;
    }

    static remove() {
        Store.remove(currentSession.user.id);
    }

    static removeAll() {
        Store.clearAll();
    }
}

// keys
LocalStorage.ShortCut = {
    // keys related Apps ShortCut
    AddNews: 'addNews', // boolean
    AddEmployee: 'addEmployee', // boolean
    AddGoal: 'addGoal', // boolean
};
LocalStorage.LeaveCalendarFilter = 'leaveCalendarFilter'; // string
LocalStorage.LeaveCalendarFilterType = 'leaveCalendarFilterType'; // string
LocalStorage.LeaveRequestCcIds = 'leaveRequestCcIds'; // string array
LocalStorage.InappBanner = {
    RemovePayrollBeta: 'RemovePayrollBeta',
};
LocalStorage.CountryNotSupported = 'CountryNotSupported'; // boolean
LocalStorage.EnableFreeTrial = 'EnableFreeTrial';
LocalStorage.AppNavigation = {
    isAppsMenuExpanded: 'isAppsMenuExpanded',
};
LocalStorage.LeavePolicy = {
    selectedOfficeId: '',
};

export default LocalStorage;
